import styles from './Testimonials.module.scss';
import svg2 from '../../assets/svS.svg';
import TestimonialCarousel from './TestimonialCarousel';

const Testimonials = () => {
    return(
        <div className={styles.testimonials}>
            <div className={styles.mainContent}>
                <h1>Testimonials</h1>
                <img src={svg2} alt='svC' width='300px' style={{paddingBottom:'50px'}}></img>
                {/* <div> */}
                    <TestimonialCarousel />
                {/* </div> */}
            </div>
        </div>
    )
}

export default Testimonials;