import img1 from '../../assets/test1.jpg';
import img2 from '../../assets/test2.jpg';
import img3 from '../../assets/test3.jpg';

const testimonialData = [
  {
    id: 1,
    quote: "I learnt Bhut Shuddhi and a review for Surya Kriya from Pratiksha akka. The experience was truly enriching. She is always available for support post class as well.",
    picture: img1, // Replace with actual image URLs
    name: "Manish Batgiri",
    profession: "Software Professional"
  },
  {
    id: 2,
    quote: "I recently learnt Jal Neti. It's daily practice has started showing positive changes within a week of learning! I've also experienced significant reduction in my allergic cold.",
    picture: img2,
    name: "Shankaran Pillay",
    profession: "Product Manager"
  },
  {
    id: 3,
    quote: "I got an opportunity to learn Surya Kriya. After the Kriya I felt so calm but still very energetic from the inside. Explained various other aspects regarding nutrition, health and overall wellbeing.",
    picture: img3,
    name: "Kalyani Khergade",
    profession: "Graphic Designer"
  }
];

export default testimonialData;