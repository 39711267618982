// src/CardCarousel.js
import React from 'react';
import { Carousel, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import carouselData from './carouselData';
import styles from './ProgramCarousel.module.scss';

function ProgramCarousel() {
  // Split data into groups of two for each carousel slide
  const groupedData = [];
  for (let i = 0; i < carouselData.length; i += 2) {
    groupedData.push(carouselData.slice(i, i + 2));
  }

  return (
    <Carousel interval={3000} controls={true} indicators={false}>
      {groupedData.map((group, index) => (
        <Carousel.Item key={index}>
          <div className="d-flex justify-content-center">
            {group.map((item) => (
              <div
                key={item.id}
                className="card m-3 p-2"
                style={{
                  width: "450px",
                  height: "auto",
                  // borderRadius: "10px",
                  boxShadow: "0 4px 8px rgba(0,0,0,0.2)"
                }}
              >
                <div className={styles.cardContent}>
                  <h5 className="card-title">{item.title}</h5>
                  <p className="card-text text-center">{item.location}</p>
                  <p className="card-text text-center">
                    {item.date}<br/>{item.time}
                  </p>
                  <div>
                    <Button variant="primary" href={item.registrationLink} className={styles.button}>
                      Register
                    </Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default ProgramCarousel;