import styles from './Hero2.module.scss';
import img from '../../assets/hathaYoga.jpg';
import svg1 from '../../assets/svB.svg';

const HathYoga = () => {
    return(
        <div className={styles.offerings}>
            <div className={styles.mainContent}>
                <div className={styles.right}>
                    <img src={img} alt='anna'></img>
                </div>
                <div className={styles.left}>
                    <img src={svg1} alt='svB'></img>
                    {/* <div> */}
                        <h2 style={{paddingTop:'20px'}}>Classical Hatha Yoga</h2>
                        <p style={{textAlign:'center'}}>
                            Hatha Yoga is rooted in a profound understanding of body mechanics, using yogic postures, or yogasanas, to prepare the system to sustain higher energy levels. Through this transformative practice, one can shape how they think, feel, and experience life. Hatha Yoga is about making the body a powerful ally, turning it into a stepping stone toward realizing your fullest potential.
                        </p>
                    {/* </div> */}
                    <img src={svg1} alt='svB'></img>
                </div>
            </div>
        </div>
    )
}

export default HathYoga;