import styles from './About.module.scss';
import svg2 from '../../assets/svS_1a.svg';

const About = () => {
    return(
        <div className={styles.about}>
            <h1>About</h1>
            <img src={svg2} alt='svC' width='300px' style={{paddingBottom:'50px'}}></img>
            <div className={styles.mainContent}>
                <div>
                    <h2>Our Mission</h2>
                    <p>Yogashaili essentially seeks to increase understanding of the ancient science of yoga; deepening awareness of yoga’s true essence and making it relevant to the fast-paced modern environment in which we live. Coming from a source that is the embodiment of yoga, this meditative approach allows you to go beyond the body and mind. We teach Sadhguru Gurukulam structured Hatha Yoga, a method rooted in the ancient systems but presented in an accessible format so that everyone can benefit from this deep yogic knowledge of the human system.</p>
                </div>
                <div>
                    <h2>About Sadhguru (Lineage)</h2>
                    <p>Sadhguru Gurukulam was founded and inspired by globally renowned yogi and international best-selling author Sadhguru Jaggi Vasudev. He first founded the Isha Foundation, which is non-profit organisation created to offer various yogic tools for human well-being. He has stated many social projects helping the rural areas of South India as well as environmental projects too. His talks on all aspects of human well-being and life have gained him international recognition leading him to speak on one of the worlds biggest stages including the UN and the World Economic Forum.
                    Sadhguru Gurukulam focuses on Classical Hatha Yoga or the physical side of yoga. This is so to reintroduce the most classical form of yoga which dates back thousands of years and bring back aspects of yoga which are largely absent today.</p>
                </div>
                <div>
                    <h2>About Pratiksha</h2>
                    <p>Pratiksha is Sadhguru Gurukulam certified Classical Hatha Yoga Teacher. She completed her Hatha Yoga training in 2023 which is 1750-hour course and attends further training every year to maintain the quality of her offering.
After doing Hatha Yoga Practices consistently for few months as the preparation of 7 Days Silence program, she noticed significant transformation within her and that motivated her to take significant step to become Sadhguru Gurukulam Certified teacher to offer transformative tools with the world.
It is Sadhguru's vision to offer this ancient science of Yoga in all its purity and make it available to every individual. As a step towards realizing this vision, Pratiksha is committed to transmit Hatha Yoga in its purest form. Pratiksha created Yogashaili as a medium to communicate the true impact of Classical Hatha Yoga and create a different message for Yoga than the one that exists mainstream. 
Having offered numerous classes in Maharashtra I beseech all of you to be part of this transformative journey.</p>
                </div>
            </div>
        </div>
    )
}

export default About;