import React, { useState } from 'react';
import { Carousel, Image } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import testimonialData from './data';
import styles from './TestimonialCarousel.module.scss';

const TestimonialCarousel = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel
      activeIndex={index}
      onSelect={handleSelect}
      controls={true}
      indicators={true}
      interval={3000}
      className={styles.carousel}
    >
      {testimonialData.map((testimonial) => (
        <Carousel.Item key={testimonial.id} className="text-center p-4">
          {/* Quote SVG */}
          <div style={{ marginBottom: '20px' }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="50"
              height="50"
              fill="#666666"
            >
              <path d="M9 11.1V10.5C9 8 7.5 6.4 5.5 6c-.4-.1-.7 0-1 .1C4 4.4 5.7 3 8 3c2.5 0 5 2.2 5 5.5v1.6c0 2.2-1.8 4.4-4 4.4H5v-.4c0-1.1.8-1.8 1.7-1.8H9v.3zm10 0V10.5c0-2.5-1.5-4.1-3.5-4.5-.3 0-.6-.1-1 .1C14 4.4 15.7 3 18 3c2.5 0 5 2.2 5 5.5v1.6c0 2.2-1.8 4.4-4 4.4h-3v-.4c0-1.1.8-1.8 1.7-1.8H19v.3z" />
            </svg>
          </div>

          {/* Quote Text */}
          <p style={{ fontStyle: 'italic', fontSize: '1.2rem' }}>"{testimonial.quote}"</p>

          {/* Client Image */}
          <div style={{ margin: '15px auto', width: '80px', height: '80px' }}>
            <Image src={testimonial.picture} roundedCircle style={{ width: '100%', height: '100%' }} alt={`${testimonial.name}'s picture`}/>
          </div>

          {/* Client Name and Profession */}
          <h5 style={{ fontWeight: 'bold' }}>{testimonial.name}</h5>
          <p style={{ color: '#666', marginBottom: '0', display:'flex', justifyContent:'center', paddingBottom:'50px' }}>{testimonial.profession}</p>

        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default TestimonialCarousel;