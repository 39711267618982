import Icon from 'react-icons-kit';
import styles from './Footer.module.scss';
import {facebookSquare} from 'react-icons-kit/fa/facebookSquare';
import {instagram} from 'react-icons-kit/fa/instagram';
import {youtubePlay} from 'react-icons-kit/fa/youtubePlay';
import {whatsapp} from 'react-icons-kit/fa/whatsapp';
import {twitter} from 'react-icons-kit/fa/twitter';
// import { Link } from 'react-scroll';
import img1 from '../../assets/CHY.png';

const Footer = () => {
    return(
        <footer class={styles.footerDistributed}>
            <div className={styles.mainContent}>

                <div class={styles.footerLeft}>
                    {/* <h3>Company<span>logo</span></h3> */}
                    <img src={img1} alt='logo'></img>
                    {/* <p class={styles.footerLinks}>
                        <Link to='home' class={styles.link1}>Home</Link>
                        <Link to='about'>About</Link>
                        <Link to='contact'>Contact</Link>
                    </p> */}
                    {/* <p class={styles.footerCompanyName}>The Designik Studio © 2024</p> */}
                </div>

                {/* <div class={styles.footerCenter}>
                    <div>
                        <i class="fa fa-map-marker"></i>
                        <p><span>1234, Karnik Nagar</span> Solapur, Maharashtra</p>
                    </div>
                    
                    <div>
                        <i class="fa fa-phone"></i>
                        <p>+91 93565 10965</p>
                    </div>

                    <div>
                        <i class="fa fa-envelope"></i>
                        <p><a href="mailto:info@yogashaili.com">info@yogashaili.com</a></p>
                    </div>
                </div> */}

                <div class={styles.footerRight}>
                    <p class={styles.footerCompanyAbout}>
                        "Hatha Yoga should not be taught as an exercise form but as a live process; then asanas will lead to ecstatic states."<br/>
                        - SADHGURU
                        <hr />
                    </p>

                    <div class={styles.footerIcons}>
                        <a href="https://www.facebook.com/share/6pt7oZc5zECW6VMC/"><Icon icon={facebookSquare} size={32} /></a>
                        <a href="https://www.instagram.com/yogashaili?utm_source=qr&igsh=MXRoN2QxMmI2aXUydw=="><Icon icon={instagram} size={32} /></a>
                        <a href="https://x.com/Itsyogashaili?t=gT2RiEoNF0uLL_ylJ1o9yg&s=09"><Icon icon={twitter} size={32} /></a>
                        <a href="http://wa.me/9356510965"><Icon icon={whatsapp} size={32} /></a>
                        <a href="https://youtube.com/@yogashaili?si=ePP63-W2YhgpFNOZ"><Icon icon={youtubePlay} size={32} /></a>
                    </div>
                </div>

            </div>
        </footer>
    )
}

export default Footer;