const carouselData = [
  {
    id: 1,
    title: "Yogasana",
    location: "Location: Pune",
    date: "5th Nov - 8th Nov 2024",
    time: "07:00 AM - 09:30 AM",
    registrationLink: "#"
  },
  {
    id: 2,
    title: "Yoga Workshop for Pregnant Women",
    location: "Location: Pune",
    date: "9th Nov - 11th Nov 2024",
    time: "07:00 AM - 08:45 AM",
    registrationLink: "#"
  },
  {
    id: 3,
    title: "Yoga Bros",
    location: "Location: Pune",
    date: "9th Nov - 11th Nov 2024",
    time: "07:00 AM - 08:45 AM",
    registrationLink: "#"
  },
  {
    id: 4,
    title: "Workshop for All",
    location: "Location: Pune",
    date: "9th Nov - 11th Nov 2024",
    time: "07:00 AM - 08:45 AM",
    registrationLink: "#"
  }
];

export default carouselData;