import './App.scss';
import Footer from './components/Footer/Footer';
import Home from './components/Home/Home';
import Navbar from './components/Navbar/Navbar';
import Angamardana from './components/Programs/Angamardana/Angamardana';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import TrialForm from './components/trial-form/trial';
// import Registration from './components/trial-form/Registration';

function App() {
  return (
    <Router>
      <Navbar />
      <section>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/angamardana" element={<Angamardana />} />
          <Route path="/:programName/registration" element={<TrialForm />} />
        </Routes>
      </section>
      {/* <section>
        <Registration />
      </section> */}
      {/* <section>
        <Angamardana />
      </section> */}
      <footer>
        <Footer />
      </footer>
    </Router>
  );
}

export default App;
