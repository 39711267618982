import React from 'react';
import styles from './Offerings.module.scss';
import OfferingCard from './OfferingCard';
import svg2 from '../../assets/svS_1a.svg';

const Offerings = () => {
    return(
        <div className={styles.offerings}>
            <h1>Our Offerings</h1>
            <img src={svg2} alt='svC' width='300px'></img>
            <div className={styles.mainContent}>
                <OfferingCard />
            </div>
        </div>
    )
}

export default Offerings;