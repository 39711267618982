import Hero from '../Hero/Hero';
import HathYoga from '../Hero2/Hero2';
import Offerings from '../Offerings/Offerings';
import UpOff from '../UpOff/UpOff';
import Testimonials from '../Testimonials/Testimonials';
import About from '../About/About';
import Contact from '../Contact/Contact';

const Home = () => {
    return(
        <>
            <section id='home'>
                <Hero />
            </section>
            <section>
                <HathYoga />
            </section>
            <section id='offerings'>
                <Offerings />
            </section>
            <section id='up-offerings'>
                <UpOff />
            </section>
            <section>
                <Testimonials />
            </section>
            <section id='about'>
                <About />
            </section>
            <section id='contact'>
                <Contact />
            </section>
        </>
    )
}

export default Home;