import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import cardData from './data'; // Import the data array
import styles from './Offerings.module.scss';
import { Link } from "react-router-dom";

function OfferingCard() {
  return (
    <div className="d-flex flex-wrap gap-4 justify-content-center">
      {cardData.map((card, index) => (
        <Card key={index} style={{ width: '18rem' }}>
          <Card.Img variant="top" src={card.image} />
          <Card.Body>
            <Card.Title>{card.title}</Card.Title>
            <Card.Text>{card.description}</Card.Text>
            <Link to={`/${card.url}`}>
              <Button variant="primary" className={styles.button}>{card.buttonText}</Button>
            </Link>
          </Card.Body>
        </Card>
      ))}
    </div>
  );
}

export default OfferingCard;
