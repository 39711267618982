import React, { useState } from 'react';
import styles from './Navbar.module.scss';
import Icon from 'react-icons-kit';
import {menu} from 'react-icons-kit/feather/menu';
import {arrowUpCircle} from 'react-icons-kit/feather/arrowUpCircle';
import { Link } from 'react-scroll';
import logo from '../../assets/logo.svg';

const Navbar = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <div className={`${styles.navbar} ${isMenuOpen ? styles.open : ''} d-flex`}>
      <div className={`${styles.wrapper}`}>
      <Link to='/' ><div className={styles.logo}>
        <img src={logo} alt='logo in svg' />
        </div></Link>
        <div className={styles.menuToggle} onClick={toggleMenu}>
          <Icon icon={menu} size={28}/>
        </div>
        <div className={styles.links}>
          <ul className={`${styles.menu} ${isMenuOpen ? styles.open : ''}`}>
            <li><Link to='home' onClick={toggleMenu}>Home</Link></li>
            <li><Link to='offerings' onClick={toggleMenu}>Offerings</Link></li>
            <li><Link to='up-offerings' onClick={toggleMenu}>Upcoming Offerings</Link></li>
            <li><Link to='about' onClick={toggleMenu}>About</Link></li>
            <li><Link to='contact' onClick={toggleMenu}>Contact</Link></li>
          </ul>
        </div>
      </div>
        <div className={styles.upHome}>
          <Link to='home' ><Icon icon={arrowUpCircle} size={32} /></Link>
        </div>
    </div>
  );
};

export default Navbar;
