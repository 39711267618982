import styles from './Hero.module.scss';
import ControlledCarousel from './ControlledCarousel';

const Hero = () => {
    return(
    <div className={styles.hero}>
        <ControlledCarousel />
    </div>
    )
}

export default Hero;